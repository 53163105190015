const development = true;

if (development)
  console.log(
    "%cScalink Development Version. https://app.scalink.com/",
    "background: rgb(0, 161, 255); color: white; padding: 5px; margin: 5px;"
  );

const urlBase = {
  API_BASE_URL_DEV: "http://localhost:3777",
  API_BASE_URL_PROD: "https://api.scalink.com/",
};

const urlCanva = {
  CANVA_URL_DEV: "http://localhost:3001/",
  CANVA_URL_PROD: "https://canva.scalink.com/",
};

const urlWhiteBoard = {
  WHITEBOARD_URL_DEV: "http://localhost:3002/",
  WHITEBOARD_URL_PROD: "https://whiteboard.scalink.com/",
};

const urlConfig = {
  API_BASE_URL: development ? urlBase.API_BASE_URL_DEV : urlBase.API_BASE_URL_PROD,
  CANVA_URL: development ? urlCanva.CANVA_URL_DEV : urlCanva.CANVA_URL_PROD,
  WHITEBOARD_URL: development ? urlWhiteBoard.WHITEBOARD_URL_DEV : urlWhiteBoard.WHITEBOARD_URL_PROD,
};

export default {urlConfig, development};
