// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const whatsapp = axios.create({
  baseURL: "https://graph.facebook.com/v15.0/100496649609796",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer EAAG6ty00tL0BAPd9bL5NsZCFFBgjQsxiBkinZAkUssUZAo6uZCPAdc9a3Gy1xABv9FHgzbyqYhQQOX6RO5mZBXZAxqD0q9fm3oYrgWAoE7cWYeHgccBKvQKaGvucKZA268jYCd0oG4xP6K07SDmdshAHT9bP11utk2I2zuFEWy8WcFKx52BvjSxZCXvR4gedm6uleyJM1XsZAMUpY1V3X1XaIjZAHNkj9ZAmdoZD"
  },
  credentials: true
});

export const getUserProfile = createAsyncThunk("appChat/getTasks", async () => {
  const response = await axios.get("/apps/chat/users/profile-user");
  return response.data;
});

export const getChatContacts = createAsyncThunk(
  "appChat/getChatContacts",
  async () => {
    const response = await api.get("/apps/chat/chats-and-contacts");
    return response.data;
  }
);

export const selectChat = createAsyncThunk("appChat/selectChat", async (id, { dispatch }) => {

    try {
      const url = "/apps/chat/chats-by-phone/" + id
      const response = await api.get(url);
      //await dispatch(getChatContacts());
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);

// export const selectChat = createAsyncThunk('appChat/selectChat', async (phoneNumber, { dispatch }) => {
//   const response = await api.get(`/apps/chat/chats-by-phone/${phoneNumber}`)
//   await dispatch(getChatContacts())
//   return response.data
// })

export const sendMsg = createAsyncThunk("appChat/sendMsg", async (obj, { dispatch }) => {

    console.log("obj", obj);

    const data = {
      "messaging_product": "whatsapp",
      "recipient_type": "individual",
      "to": obj.Telefone,
      "type": "text",
      "text": {
          "preview_url": false,
          "body": obj.message
        }
    };
    
    const res = await whatsapp.post('/messages', data);

    /*const response = await axios.post("/apps/chat/send-msg", { obj });
    await dispatch(selectChat(obj.Telefone));*/
    return response.data;
  }
);

export const appChatSlice = createSlice({
  name: "appChat",
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload;
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        state.chats = action.payload.chats;
        state.contacts = action.payload.contacts;
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      });
  },
});

export default appChatSlice.reducer;
